<template>
    <div class="view-painel-eletronico">
        <div class="container-fluid">
            <transition
                name="custom-classes-transition"
                enter-active-class="animated bounceInLeft"
                leave-active-class="animated bounceOutRight"
            >
                <div class="proposicao">
                    <div>
                        <div class="subtitulo">
                            <i class="fas fa-file-alt"></i>
                            <h5 class="d-inline-block ml-2">Proposições Expediente</h5>
                        </div>

                        <SpinnerDefault class="spinner-border-sm text-light" :loading="loadingInfo" />
                        <div class="proposicoes-div">
                            <div
                                class="proposicao-div my-3"
                                v-show="!loadingInfo"
                                v-for="proposicao in proposicoesExpediente"
                                :key="proposicao.id"
                            >
                                <div class="py-2 px-4 texto-arquivo">
                                    {{ proposicao.titulo_telao }}
                                    {{
                                        proposicao.proposicao_modificada
                                            ? ' ao ' + proposicao.proposicao_modificada.titulo_telao
                                            : ''
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="subtitulo">
                            <i class="fas fa-file-alt"></i>
                            <h5 class="d-inline-block ml-2">Proposições Ordem do Dia</h5>
                        </div>
                        <SpinnerDefault class="spinner-border-sm text-light" :loading="loadingInfo" />
                        <div class="proposicoes-div">
                            <div
                                class="proposicao-div my-3"
                                v-show="!loadingInfo"
                                v-for="proposicao in proposicoesOrdemDoDia"
                                :key="proposicao.id"
                            >
                                <div class="proposicao-texto">
                                    <div class="py-2 pl-4 texto-arquivo">
                                        {{ proposicao.titulo_telao }}
                                        {{
                                            proposicao.proposicao_modificada
                                                ? ' ao ' + proposicao.proposicao_modificada.titulo_telao
                                                : ''
                                        }}
                                    </div>
                                    <template v-if="proposicao.votacoes[0]">
                                        <div
                                            class="proposicao-status"
                                            :class="{
                                                aprovada: proposicao.votacoes[0].votacao_status_id == 1,
                                                reprovada: proposicao.votacoes[0].votacao_status_id == 2
                                            }"
                                        >
                                            <span v-if="proposicao.votacoes[0].votacao_status_id == 1"> Aprovada </span>
                                            <span v-else-if="proposicao.votacoes[0].votacao_status_id == 2">
                                                Reprovada
                                            </span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import SessaoServiceTelao from '../domain/SessaoServiceTelao';
const SpinnerDefault = () => import('../components/shared/SpinnerDefault.vue');

export default {
    components: {
        SpinnerDefault
    },
    props: {
        telaoSettings: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            sessaoServiceTelao: new SessaoServiceTelao(this.$route.params.dominio),
            sessao: null,
            loadingSessaoDatas: true,
            loadingInfo: true,
            proposicoesExpediente: [],
            proposicoesOrdemDoDia: [],
            proposicaoExpedienteArquivoSelected: undefined,
            proposicaoOrdemDoDiaArquivoSelected: undefined,
            proposicaoOrdemDoDiaSelected: null
        };
    },

    created: async function() {
        const dominio = this.$route.params.dominio;
        const ENDPOINT_BASE = `portyx_${dominio}-sessao-${this.$sessaoId}`;
        window.Echo.channel(`${ENDPOINT_BASE}-proposicaoArquivoCurrent`).listen(
            'ProposicaoArquivoCurrentSelected',
            ({ proposicaoArquivoId, proposicaoTipo, proposicaoId }) => {
                if (proposicaoTipo == 'expediente') {
                    this.proposicaoOrdemDoDiaArquivoSelected = undefined;
                    this.proposicaoExpedienteArquivoSelected = proposicaoArquivoId;
                    this.proposicaoOrdemDoDiaSelected = undefined;
                } else {
                    this.proposicaoExpedienteArquivoSelected = undefined;
                    this.proposicaoOrdemDoDiaArquivoSelected = proposicaoArquivoId;
                    this.proposicaoOrdemDoDiaSelected = proposicaoId;
                }
            }
        );

        this.sessaoServiceTelao
            .showSessaoProposicao(this.$sessaoId)
            .then(response => this.showSessaoDatas(response))
            .catch(err => err);
    },

    methods: {
        async showSessaoDatas(response) {
            this.sessao = response.data.sessao;
            this.proposicoesExpediente = response.data.proposicoesExpediente;
            this.proposicoesOrdemDoDia = response.data.proposicoesOrdemDoDia;

            await this.sessaoServiceTelao.getCurrentProposicaoArquivo(this.$sessaoId).then(response => {
                if (response.data.proposicaoArquivoCurrent) {
                    if (response.data.proposicaoArquivoCurrent.pivot.expediente == true) {
                        this.proposicaoExpedienteArquivoSelected = response.data.proposicaoArquivoCurrent.id;
                    } else {
                        this.proposicaoOrdemDoDiaSelected = response.data.proposicaoArquivoCurrent.pivot.proposicao_id;
                        this.proposicaoOrdemDoDiaArquivoSelected = response.data.proposicaoArquivoCurrent.id;
                    }
                }
            });

            this.loadingInfo = false;
        }
    }
};
</script>

<style scoped lang="scss">
.view-painel-eletronico {
    font-size: 12px;
    @media (min-width: 640px) {
        font-size: 14px;
    }
    @media (min-width: 768px) {
        font-size: 16px;
    }
    @media (min-width: 1024px) {
        font-size: 17px;
    }
    @media (min-width: 1280px) {
        font-size: 19px;
    }
    @media (min-width: 1536px) {
        font-size: 22px;
    }
    @media (min-width: 2000px) {
        font-size: 24px;
    }
    @media (min-width: 2400px) {
        font-size: 26px;
    }
    @media (min-width: 2800px) {
        font-size: 28px;
    }
    @media (min-width: 3200px) {
        font-size: 30px;
    }
    @media (min-width: 3600px) {
        font-size: 33px;
    }
    @media (min-width: 4000px) {
        font-size: 36px;
    }
}

.titulo-principal {
    padding: 15px;
    color: #fff;
    font-size: 1.5em;
    text-align: center;
}

.subtitulo {
    width: 100%;
    display: flex;
    gap: 5px;
    color: rgba(255, 255, 255, 0.92);
    padding: 0.6em;
    margin-bottom: 25px;
    align-items: center;
    border-bottom: 1px solid rgba($color: #fff, $alpha: 0.8);

    & h5 {
        margin-bottom: 0px;
        font-size: 2em;
        font-weight: bold;
        text-shadow: 0 0 2px #ddd;
    }

    & i {
        font-size: 1.75em;
        text-shadow: 0 0 1px #ddd;
    }
}

.proposicoes-div {
    height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
}

.proposicoes-div::-webkit-scrollbar-track
{
	background-color: #041f35;
}
.proposicoes-div::-webkit-scrollbar
{
	width: 8px;
	background-color: #041f35;
}
.proposicoes-div::-webkit-scrollbar-thumb
{
	background-color: #ffffff;
}

.texto-arquivo {
    font-size: 1.2em;
    text-align: left;
}

.proposicao {
    display: grid;
    gap: 20px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
        gap: 40px;
    }

    @media (min-width: 992px) {
        gap: 60px;
    }

    @media (min-width: 2000px) {
        gap: 120px;
    }

    &-texto {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-encerrada {
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #000;
        padding: 15px 40px;
        border-radius: 10px;
        font-size: 1em;
        width: 80%;
    }

    &-status {
        color: #fff;
        padding: 2px 6px;
        margin-right: 20px;
        border-radius: 15px;
        font-weight: bold;

        &.aprovada {
            background-color: #0a9a0a;
        }

        &.reprovada {
            background-color: #e62323;
        }
    }

    &-div {
        color: white;
        border-radius: 3px;
        background-color: rgba($color: #000000, $alpha: 0.2);
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

.highlight {
    transition: 0.7s;
    background-color: rgba($color: #fff, $alpha: 0.9);
    color: #000;
    font-weight: bold;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
</style>
